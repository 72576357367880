import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
    
    constructor(private toastr: ToastrService) {
    }

    SuccessNotification(message) { 
        this.toastr.success(message, 'Thành công', {
            timeOut: 5000
        });
    }

    ErrorNotification(error) {
        this.toastr.error(error, 'Thất bại', {
            timeOut: 5000
        });
    }

    WarningNotification(message,time) { 
        this.toastr.warning(message, 'Thông báo', {
            timeOut: time
        });
    }
}