import { Injectable } from '@angular/core';

@Injectable()
export class DataStorage {
  public static user: any = {};
  public static schedual: any = {};
  public static office: any = {};
  public static staff: any = {};
  public static login: any = {};
  public static confirmTime: any = {};
  public static vpcc: any = {};
  public static step2Data: any = {};
  public static step3Data: any = {};
  public static step99Data: any = {};
  public static userLogin: any = {};
  public static step5Data: any = {};
  public static dataListVPCC: any = {};
  public static dataListCCV: any = {};
  public static customer: any = {};
  // new name variable
  // chat variable
  public static chatlog: any = {};
  public static modelDocument: any = {};
  //step BoSung
  public static checkBosung: boolean = false;
  public static saveImage: any = {};
  public static ketquatimkiem: any = {};
  public static hosonhap: any = {};
  public static thongtinhosonhap: any = {};

  public static stateCongChung = {
    requireLogin: 0,
    hinhThucCC: 1,
    thongTinHs: 2,
    chonVpCC: 3,
    xacNhanHs: 4,
    datLichCC: 5,
    hoaDonTT: 6,
    xacNhanOTP: 7,
    hoanThanh: 8,
    uploadImageHs: 99
  }

  //steps
  public static dataCreateDocument: any = {
    DienThongTin: {},
    UploadImageHoso: {},
    HoaDonThanhToan: {},
    ChonVanPhongCongChung: {},
    DatLichCongChung: {},
  }

  public static ResetDataHoSoCongChung() {
    DataStorage.dataCreateDocument = {
      DienThongTin: {},
      UploadImageHoso: {},
      HoaDonThanhToan: {},
      ChonVanPhongCongChung: {},
      DatLichCongChung: {},
    };
  }
}
