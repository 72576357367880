import { Injectable } from "@angular/core";
import { RequestMethod } from '@angular/http';
import { CustomRequest } from '../../libs/request';
@Injectable()
export class RegisterService{
    constructor(private _Request: CustomRequest){

    }
    Register(RegisterModal){
        return this._Request.Request('api/v1/Account/Register',RequestMethod.Post,RegisterModal);
    }
}