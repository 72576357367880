import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
// import { JwtHelper } from 'angular2-jwt';
import { SystemConstants } from '../core/common/system.constant';
//import * as JWT from 'jwt-decode';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {
  }
  async canActivate() {
    // const user = JSON.parse(localStorage.getItem(SystemConstants.CURRENT_USER));
    // if(!user) {
    //   this.router.navigate(['login']);
    //   return;
    // }
    // const jwtDecoder = <any>JWT(user);
    // // if (user && !this.jwtHelper.isTokenExpired(user)) {
    // //   return true;
    // // }
    // if (jwtDecoder == null || jwtDecoder === '') {
    //   this.router.navigate(['login']);
    //   return false; 
    // }
    return true;
  }

}
