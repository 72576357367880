import { Injectable } from "@angular/core";
import { RequestMethod } from '@angular/http';
import { CustomRequest } from '../../libs/request';
@Injectable()
export class verifyAccount{
    constructor(private _Request: CustomRequest){

    }
    verify(RegisterModal){
        return this._Request.Request('api/v1/Account/Active',RequestMethod.Post,RegisterModal);
    }
    reCreateOTP(Model){
        return this._Request.Request('api/v1/Account/ReSendOTP',RequestMethod.Post,Model);
    }
}