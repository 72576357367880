import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../model/user';
import { NotificationService } from '../../libs/notification';
import { LoginService } from './login.component.service';
import { DataService } from '../../core/services/data.service';
import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
//193647885059816
export class LoginComponent implements OnInit {
  model: any = {};
  invalidLogin: boolean;
  user: User = {
    username: "",
    password: "",
    token:""
  }
  private userLogin: SocialUser;
  public authorized: boolean = false;
  //display boolean
  displayMain: boolean = true;
  socialUser: any = {};
  constructor(
    private socialAuthService: AuthService,
    private dataService: DataService,
    private loginService: LoginService,
    private router: Router,
    private noti: NotificationService,
    private translate: TranslateService
  ) { }
  ngOnInit() {
  }
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        if (socialPlatform == "google") {
          this.signInGoogle(userData);
        }
        if (socialPlatform == "facebook") {
          this.signinFacebook(userData);
        }
        // Now sign-in with userData        
        if (userData != null) {
          this.authorized = true;
          this.userLogin = userData;
        }
      }
    );
  }
  signinFacebook(userData) {
    this.socialUser.email = userData.email;
    this.socialUser.fullname = userData.name;
    this.socialUser.username = userData.name;
    this.socialUser.socialid = userData.id;
    this.socialUser.avatar = userData.image;
    this.socialUser.type_social = 1;
    this.dataService.signInSocial(this.socialUser).subscribe((res) => {
      let temp = res.result;
      localStorage.userId = temp.id;
      localStorage.tokenUser = temp.token;
      this.GotoMain();
    });
  }

  signInGoogle(userData) {
    this.socialUser.email = userData.email;
    this.socialUser.fullname = userData.name;
    this.socialUser.username = userData.name;
    this.socialUser.socialid = userData.id;
    this.socialUser.avatar = userData.image;
    this.socialUser.type_social = 2;
    this.dataService.signInSocial(this.socialUser).subscribe((res) => {
      let temp = res.result;
      localStorage.userId = temp.id;
      localStorage.tokenUser = temp.token;
      this.GotoMain();
    });
  }
  //login function
  Login() {
    var msg = "";
    if (!this.user.username.trim()) {
      msg = this.translate.instant("Login.RequireName");
    }
    if (!this.user.password.trim() && !msg) {
      msg = this.translate.instant("Login.RequirePassword");
    }
    if (msg) {
      this.noti.ErrorNotification(msg);
      return;
    } this.displayMain = false;
    this.loginService.Login(this.user).subscribe((res) => {
      this.displayMain = true;
      if (JSON.stringify(res.status) === 'true') {
        this.noti.SuccessNotification(this.translate.instant("Login.Success"));
        localStorage.userId = res.id;
        localStorage.tokenUser = res.token;
        this.GotoMain();
      }
      else {
        this.noti.ErrorNotification(this.translate.instant("Login.EnterAgain"));
      }
    }, error => {
      this.noti.ErrorNotification(error);
    });
  }
  //router
  GotoMain() {
    this.router.navigate(['home']);
  }
  GotoRegister() {
    this.router.navigate(['register']);
  }
  GotoForgotPassword() {
    this.router.navigate(['forgot-password']);
  }
  //keydown Event
  onKeydown(event) {
    if (event.key === "Enter") {
      this.Login();
    }
  }
}
