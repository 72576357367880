import { Injectable } from "@angular/core";
import { RequestMethod } from '@angular/http';
import { CustomRequest } from '../../libs/request';
@Injectable()
export class LoginService {

  constructor(private _Request: CustomRequest) {
  }
  Login(modaluser) {
    return this._Request.Request('api/v1/Account/login', RequestMethod.Post, modaluser);
  }
}
