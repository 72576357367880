import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { HoanThanhComponent } from './step-cong-chung/hoan-thanh/hoanthanh.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { rejects } from 'assert';

@Component({
  selector: 'app-app',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: []
})
export class MainComponent implements OnInit {
  @ViewChild('infoTaoCongChung', { static: true }) infoTaoCongChung: HoanThanhComponent;
  statusPay: any;
  documentId: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private cookieService :CookieService) {
      const language = localStorage.getItem('language');
      translate.use(language && language.match(/en|vi/)? language : 'vi');
      this.route.queryParams.subscribe(params => {
        this.statusPay = params['status'];
        this.documentId = params['idhoso'];
        this.checkPayment();
    });
  }

  ngOnInit() {
  }

  checkPayment() {
    setTimeout(() => {
      this.confirmSuccessOrFailt();
    }, 1000);
  }

  // check payment
  confirmSuccessOrFailt() {
    
    if (localStorage.tienmat) {
      this.infoTaoCongChung.show();
      localStorage.removeItem('tienmat');
      return;
    }

    if (this.statusPay == 1 && localStorage.tokenUser && localStorage.vnpay) {
      this.infoTaoCongChung.show();
      localStorage.removeItem('vnpay');
      return;
    }

    if (this.statusPay == 0 && localStorage.tokenUser) {
      this.confirmationService.confirm({
        message: 'Thanh thoán thất bại',
      });
    }

    if(this.cookieService.get('statusPay') =='0' && localStorage.tokenUser){
      this.confirmationService.confirm({
        header:'Thông báo',
        message: 'Có hồ sơ chưa hoàn tất, bạn có muốn tiếp tục?',
        accept: ()=>{
          this.router.navigate(['documents']);
        },
        reject: () =>{
          this.clearCookiePay();
        }
      });
    };
  }


  clearCookiePay(){
    this.cookieService.delete('statusPay');
    this.cookieService.delete('documentId');
  }
}
