import { Injectable } from '@angular/core';
import { Http, RequestOptions, Response, Headers } from '@angular/http';
import { SystemConstants } from '../common/system.constant';
import { CustomRequest } from '../../libs/request';
import { RequestMethod } from '@angular/http';

@Injectable()
export class AuthenService {
  baseUrl = '';
  constructor(private _http: Http, private _Request: CustomRequest) {
    this.baseUrl = SystemConstants.BASE_API;
   }

  public login(model) {
    return this._Request.Request("api/v1/Account/login", RequestMethod.Post, model);
  }
}
