import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../libs/notification';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../core/services/data.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  email: string = "";

  constructor(
    private router: Router,
    private noti: NotificationService,
    private translate: TranslateService,
    private dataservice: DataService
  ) { }
  ngOnInit() {

  }

  forgotPassword() {
    const regexCheckmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    const regexEmail = new RegExp(regexCheckmail);
    const regexPhone = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$');
    var checkPhoneNumber = /^0(1\d{9}|9\d{8})$/;
    var msg = "";

    if (!this.email.trim()) {
      msg = this.translate.instant("ForgotPassword.EmailRequire");
    }

    if (msg) {
      this.noti.ErrorNotification(msg);
    }
    else {
      let checkEmail = regexCheckmail.test(this.email.trim());
      let checkPhone = checkPhoneNumber.test(this.email.trim());
      if (checkEmail || checkPhone) {
        if (checkEmail) {
          let model = {
            email: this.email
          }
          this.dataservice.forgotPassWord(model).subscribe((res) => {
            console.log(res);
            if(res.status === true){
              this.noti.SuccessNotification(this.translate.instant("ForgotPassword.Success"));
            }
            else if(res.status === false){
              this.noti.ErrorNotification(res.error);
            }
          });
        }
        else {
          let model = {
            phone: this.email
          }
          this.dataservice.forgotPassWord(model).subscribe((res) => {
            console.log(res);
            if(res.status === true){
              this.noti.SuccessNotification(this.translate.instant("ForgotPassword.Success"));
            }
            else if(res.status === false){
              this.noti.ErrorNotification(res.error);
            }
          });
        }
        //this.noti.SuccessNotification(this.translate.instant("ForgotPassword.Success"));
      }
      else {
        this.noti.ErrorNotification(this.translate.instant("ForgotPassword.Error"));
      }
    }
  }

  GotoLogin() {
    this.router.navigate(['login']);
  }

}
