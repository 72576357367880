import { Component, OnInit, ViewChild,Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { AppComponentBase } from '../../../../shared/app-component-base';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ThrowStmt } from '@angular/compiler';
import { DataStorage } from '../../../../shared/data-storage';
import { NgxQRCodeModule } from 'ngx-qrcode2';
@Component({
  selector: 'app-step-tao-hs-hoanthanh',
  templateUrl: './hoanthanh.component.html',
  styleUrls: ['./hoanthanh.component.scss']
})
export class HoanThanhComponent extends AppComponentBase {
  @ViewChild('moldalStepCongChung', { static: true }) modalChungthuc: ModalDirective;
  constructor(
    spinnerService: Ng4LoadingSpinnerService,
    injector: Injector) {
      super(injector,spinnerService); 
    }
  transid : string = "";
  transtext: string = "";
  title = 'app';
  elementType = 'img';
  value = '';
  ngOnInit() {
    this.transid = localStorage.hoanthanhhoso;
    if(this.transid !== ""){
      this.value = this.transid;
    }
    else{
      this.transtext = "Nộp hồ sơ công chứng thành công";
    }
  }
  show() {
    this.modalChungthuc.show();
  }
  hide() {
    this.modalChungthuc.hide();
  }
}
