import { AuthenService } from './../../core/services/auth-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { register } from '../../model/register';
import { User } from '../../model/user';
import { NotificationService } from '../../libs/notification';
import { RegisterService } from '../register/register.component.service';
import { DataStorage } from '../../shared/data-storage';
import { verifyAccount } from '../verify-account/verify-account.component.service'
import { DataService } from '../../core/services/data.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})

export class VerifyAccountComponent implements OnInit {
  model: any = {};
  email: string = "";
  OTPstring1: string;
  OTPstring2: string;
  OTPstring3: string;
  OTPstring4: string;
  OTP: string;

  dangKy: register = {
    phone: "",
    fullname: "",
    email: "",
    password: ""
  }

  constructor(private cookieService: CookieService,
    private dataService: DataService,
    private router: Router,
    private noti: NotificationService,
    private verifyService: verifyAccount) {

  }
  // tu dong nhay sang the input moi
  moveOnMax2() {
    document.getElementById("2").focus();
  }
  moveOnMax3() {
    document.getElementById("3").focus();
  }
  moveOnMax4() {
    document.getElementById("4").focus();
  }
  movetoHome() {
    this.router.navigate(['home']);
  }
  // active OTP
  activeOTP() {
    this.OTP = this.OTPstring1 + this.OTPstring2 + this.OTPstring3 + this.OTPstring4;
    this.model.userid = DataStorage.user.id;
    this.model.otp = this.OTP;
    this.verifyService.verify(this.model).subscribe((res) => {
      if (JSON.stringify(res.status) === 'true') {
        this.noti.SuccessNotification("Đăng ký tài khoản thành công");
        this.login();

      }
      else {
        this.noti.ErrorNotification("Mời nhập lại mã số kích hoạt");
      }
    });
  }
  login() {
    this.dataService.Login(DataStorage.login).subscribe((res) => {
      if (JSON.stringify(res.status) === 'true') {
        this.noti.SuccessNotification("Đăng nhập thành công");
        localStorage.userId = res.id;
        localStorage.tokenUser = res.token;
        this.cookieService.set('token', res.token);
        this.router.navigate(['home']);
      }
    });
  }


  reCreatdOTP(){
    let model :any ={};
    model.phone = DataStorage.login.username;
    this.verifyService.reCreateOTP(model).subscribe((res) => {
      if (JSON.stringify(res.status) === 'true') {
        this.noti.SuccessNotification("OTP mời đã được gửi lại. Vui lòng kiếm tra tin nhắn điện thoại.");
      }
      else {
        this.noti.ErrorNotification("Có lỗi xảy ra.");
      }
    });
  }


  ngOnInit() {

  }

}
