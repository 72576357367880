import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './layout/login/login.component';
import { RegisterComponent } from './layout/register/register.component';
import { ForgotPasswordComponent } from './layout/forgot-password/forgot-password.component';
import { VerifyAccountComponent } from './layout/verify-account/verify-account.component';
import { MainComponent } from './layout/main/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: './layout/main/main.module#MainModule',
  },
  {
    path: 'home',
    component: MainComponent,
    loadChildren: './layout/main/main.module#MainModule',
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'verify-account',
    component: VerifyAccountComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }






// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { AdminComponent } from './layout/admin/admin.component';
// import { LoginComponent } from './layout/login/login.component';
// import { AuthGuard } from './guards/auth-guard.service';

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'home',
//     pathMatch: 'full'
//   },
//   {
//     path: 'login',
//     component: LoginComponent
//   },
//   {
//     path: 'home',
//     component: AdminComponent,
//     canActivate: [AuthGuard],
//     // children: [
//     //   {
//     //     path: '',
//     //     redirectTo: 'home',
//     //     pathMatch: 'full',
//     //     canActivate: [AuthGuard]
//     //   },
//     //   {
//     //     path: 'home',
//     //     loadChildren: './main/production/production.module#ProductionModule',
//     //     canActivate: [AuthGuard]
//     //   },
//     // ]
//   },
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
