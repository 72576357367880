import { Component, Injector, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { register } from '../../model/register';
import { NotificationService } from '../../libs/notification';
import { RegisterService } from '../register/register.component.service';
import { DataStorage } from '../../shared/data-storage';
import { AppComponentBase } from '../../shared/app-component-base';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent extends AppComponentBase {
  model: any = {};
  email: string = '';

  dangKy: register = {
    phone: '',
    fullname: '',
    email: '',
    password: ''
  }

  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;
  private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

  constructor(
    spinnerService: Ng4LoadingSpinnerService,
    injector: Injector,
    private registerService: RegisterService,
    private router: Router,
    private noti: NotificationService) {
    super(injector, spinnerService);
  }

  ngOnInit() {

  }
  AccountRegister() {
    var msg = '';
    const regexCheckmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    const regexEmail = new RegExp(regexCheckmail);
    var checkPhoneNumber = /^[0-9][A-Za-z0-9 -]*$/;
    // var pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    var pass = /^\w{8,}$/;

    if (!this.dangKy.fullname.trim()) {
      msg = this.l('Register.AllInfo');
      document.getElementById('fullname').style.borderBottomColor = 'red';
      document.getElementById('fullname').focus();
    }

    if (this.dangKy.email.trim() && !regexCheckmail.test(this.dangKy.email.trim()) && !msg) {
      msg = this.l('Register.EmailFormat');
      document.getElementById('email').style.borderBottomColor = 'red';
    }

    if (!this.dangKy.phone.trim()) {
      msg = this.l('Register.AllInfo');
      document.getElementById('phone').style.borderBottomColor = 'red';
    }

    if (!checkPhoneNumber.test(this.dangKy.phone.trim()) && !msg) {
      msg = this.l('Register.PhoneFormat');
      document.getElementById('phone').style.borderBottomColor = 'red';
    }

    if (!this.dangKy.password.trim()) {
      msg = this.l('Register.FillPassword');
      document.getElementById('password').style.borderBottomColor = 'red';
    }

    if (!pass.test(this.dangKy.password.trim()) && !msg) {
      msg = this.l('Register.PasswordFormat');
      document.getElementById('password').style.borderBottomColor = 'red';
    }

    if (!this.dangKy.fullname.trim() && !this.dangKy.phone.trim() && !this.dangKy.password.trim()) {
      msg = this.l('Register.AllInfo');
      document.getElementById('password').style.borderBottomColor = 'red';
      document.getElementById('phone').style.borderBottomColor = 'red';
      document.getElementById('fullname').style.borderBottomColor = 'red';
    }

    if (msg.trim()) {
      this.noti.ErrorNotification(msg);
      return;
    }

    this.registerService.Register(this.dangKy).subscribe((res) => {
      if (JSON.stringify(res.status) === 'true') {
        DataStorage.user.username = this.dangKy.fullname;
        DataStorage.user.id = res.userid;
        DataStorage.login.username = this.dangKy.phone;
        DataStorage.login.password = this.dangKy.password;
        this.router.navigate(['verify-account']);
      }
      else {
        this.noti.ErrorNotification(res.error)
      }

    }, error => {
      this.noti.ErrorNotification(error);
    });

  }

  GotoLogin() {
    this.router.navigate(['login']);
  }


  //strength password checker
  measureStrength(pass: string) {
    let score = 0;
    // award every unique letter until 5 repetitions  
    let letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up  
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      special: /[$&+,:;=?@#|]/.test(pass),
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount) * 10;
    console.log(score)
    return Math.trunc(score);
  }


  private getColor(score: number) {
    let idx = 0;
    if (score > 90) {
      idx = 4;
    } else if (score > 70) {
      idx = 3;
    } else if (score >= 40) {
      idx = 2;
    } else if (score >= 20) {
      idx = 1;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  //trigger password change
  passwordChange(): void {
    var password = this.dangKy.password;
    this.setBarColors(5, '#DDD');
    if (password) {
      let c = this.getColor(this.measureStrength(password));
      this.setBarColors(c.idx, c.col);
    }
  }

  //set color bar
  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }
}
