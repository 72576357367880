import { Injectable } from '@angular/core';
import { Http, RequestOptions, Response, Headers } from '@angular/http';
import { SystemConstants } from '../common/system.constant';
import { CustomRequest } from '../../libs/request';
import { RequestMethod } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class DataService {
  baseUrl = '';

  constructor(private _http: Http, private _Request: CustomRequest) {
    this.baseUrl = SystemConstants.BASE_API;
  }
  public updateStatusDocumentAdded(model) {
    return this._Request.RequestWithToken("api/v1/documentary/added", RequestMethod.Post, model);
  }

  public changePass(model) {
    return this._Request.RequestWithToken("/api/v1/Account/ChangePass", RequestMethod.Post, model);
  }

  public forgotPassWord(model) {
    return this._Request.RequestWithToken('api/v1/Account/forgotPass', RequestMethod.Post, model);
  }
  public uploadAvatar(model) {
    return this._Request.RequestWithToken("/api/v1/Account/Update_Avatar", RequestMethod.Post, model);
  }
  public getimageUploadLibrary(model) {
    return this._Request.RequestWithToken("/api/v1/customer/getfile?customerid=" + model + "&typeid=0", RequestMethod.Get)
  }
  public removeDocument(model) {
    return this._Request.RequestWithToken("/api/v1/documentary/cancel", RequestMethod.Post, model);
  }
  public getfullservicelevel2() {
    return this._Request.Request("/api/v1/getservicesbyspecid?length=9999", RequestMethod.Get);
  }
  public searchService(search) {
    return this._Request.Request("/api/v1/getservicesbyspecid?search=" + search, RequestMethod.Get);
  }
  public updateTypePayment(model) {
    return this._Request.RequestWithToken('/api/v1/order/update_type_payment', RequestMethod.Post, model);
  }
  public khanghangvip() {
    return this._Request.Request('/api/v1/tbl_StaticContent/GetByKey/KHACH_HANG_VIP', RequestMethod.Get);
  }
  public getStaticContent(key) {
    return this._Request.Request('/api/v1/tbl_StaticContent/GetByKey/' + key, RequestMethod.Get);
  }
  public getNeW(id) {
    return this._Request.Request('/api/v1/new/detail/' + id, RequestMethod.Get);
  }
  public getMgsRoom(model) {
    return this._Request.RequestWithToken('/api/v1/Messenger/GetList?start=' + model.start + '&length=' + model.length + '&search=&roomid=' + model.roomid + '&userid=' + model.userid + '&token=' + model.tokenUser, RequestMethod.Get);
  }
  public sendMgs(model, tokenUser) {
    return this._Request.RequestWithToken('/api/v1/Messenger/SendMessenger?token=' + tokenUser, RequestMethod.Post, model);
  }
  public sendFile(model, tokenUser) {
    return this._Request.RequestWithToken('/api/v1/file/upload?token=' + tokenUser, RequestMethod.Post, model);
  }
  public joinRoom(model, tokenUser) {
    return this._Request.RequestWithToken('/api/v1/Room/Join?token=' + tokenUser, RequestMethod.Post, model);
  }
  public createRoomChat(model, tokenUser) {
    return this._Request.RequestWithToken('/api/v1/Room/Create?token=' + tokenUser, RequestMethod.Post, model);
  }
  public getServiceLevel1(model) {
    return this._Request.Request('/api/v1/specializations?start=' + model.start + '&length=' + model.length, RequestMethod.Get);
  }

  public getServiceLevel1ByVPCCId(model) {
    return this._Request.Request('/api/v1/VPCC_Service/ListSpecializationByVPCCID?start=' + model.start + '&length=' + model.length+'&vpccid=' + model.vpccid, RequestMethod.Get);
  }

  public getServiceLevel2(model) {
    return this._Request.Request('/api/v1/getservicesbyspecid?specid=' + model + '&start=0&length=9999', RequestMethod.Get);
  }

  public getServiceLevel2ByVPCC(model) {
    var url = '/api/v1/getservicesbyspecid?specid='+model.specid;
    if (model.vpccid) { url = url + '&vpccid=' + model.vpccid; }
    return this._Request.Request(url + '&start=0&length=9999', RequestMethod.Get);
  }
  public getServiceLevel2Paging(model) {
    return this._Request.Request('/api/v1/getservicesbyspecid?specid=&start=' + model.start + '&length=' + model.length + '&search=' + (model.search ? model.search : ''), RequestMethod.Get);
  }
  public getNews(model) {
    return this._Request.Request('/api/v1/new/list?start=0&length=' + model.length, RequestMethod.Get);
  }
  public getNotify(model) {
    return this._Request.RequestWithToken('/api/v1/Notification/getbyuser?userid=' + model, RequestMethod.Get);
  }
  public readNotify(model) {
    return this._Request.RequestWithToken('/api/v1/Notification/read', RequestMethod.Post,model);
  }

  public clickNotify(model) {
    return this._Request.RequestWithToken('/api/v1/Notification/click', RequestMethod.Post,model);
  }

  public GetListService(model) {
    return this._Request.Request('/api/v1/services?start=' + model.start + '&length=' + model.length, RequestMethod.Get);
  }

  public signInSocial(model) {
    return this._Request.Request('/api/v1/Account/LoginSocial', RequestMethod.Post, model);
  }

  public GetInfoCustommer(id: string) {
    return this._Request.RequestWithToken('/api/v1/customer/getdetailbyuserid/' + id, RequestMethod.Get);
  }

  public getListOfficeNotari(model) {
    return this._Request.Request('/api/v1/vpcc?start=' + model.start + '&length=' + model.length + '&province=' + model.province + '&district=' + model.district, RequestMethod.Get);
  }

  public getListOfficeNotari2(model) {
    return this._Request.Request('/api/v1/vpcc?start=' + model.start + '&length=' + model.length + '&search=' + model.search + '&province=' + model.province + '&district=' + model.district, RequestMethod.Get);
  }

  public getalloffice() {
    return this._Request.Request('/api/v1/vpcc?length=9999', RequestMethod.Get);
  }


  public getdetailService(model) {
    return this._Request.Request('/api/v1/service/detail/' + model, RequestMethod.Get);
  }

  public getListNotarier(officeId: string) {
    return this._Request.Request('/api/v1/staff?start=&length=9999&vpccid=' + officeId, RequestMethod.Get);
  }

  public getdetailOffice(id) {
    return this._Request.Request('/api/v1/vpcc/detail/' + id, RequestMethod.Get);
  }
  public getListNotarier2(model) {
    return this._Request.Request('/api/v1/staff?start=&length=' + model.length + '&province=' + model.vpcc_province + '&district=' + model.vpcc_district, RequestMethod.Get);
  }

  public getListNotarier3(model) {
    // return this._Request.Request
    //   ('/api/v1/staff?start=' + model.start + '&length=' + model.length + '&province='
    //     + model.vpcc_province + '&district=' + (model.vpcc_district ? model.vpcc_district : '')
    //     + '&search=' + (model.search ? model.search : ''), RequestMethod.Get);
    var url = '/api/v1/staff?';
    if (model.start) { url = url + '&start=' + model.start; }
    if (model.length) { url = url + '&length=' + model.length; }
    if (model.search) { url = url + '&search=' + model.search; }
    if (model.latlng && model.latlng != '(undefined,undefined)') { url = url + '&latlng=' + model.latlng; }
    if (model.province) { url = url + '&province=' + model.province; }
    if (model.district) { url = url + '&district=' + model.district; }
    return this._Request.Request(url, RequestMethod.Get);
  }
  public getListOfficeNotari3(model) {
    var url = '/api/v1/vpcc?';
    if (model.start) { url = url + '&start=' + model.start; }
    if (model.length) { url = url + '&length=' + model.length; }
    if (model.search) { url = url + '&search=' + model.search; }
    if (model.latlng && model.latlng != '(undefined,undefined)') { url = url + '&latlng=' + model.latlng; }
    if (model.province) { url = url + '&province=' + model.province; }
    if (model.district) { url = url + '&district=' + model.district; }
    return this._Request.Request(url, RequestMethod.Get);
  }
  public getFileDocumentRequest(id: string) {
    return this._Request.Request('/api/v1/services/list_file/' + id, RequestMethod.Get);
  }

  public getFileDocumentRequestByVPCCID(model) {
    var url = '/api/v1/services/listFileByVPCC?id='+model.id;
    if (model.vpccid) { url = url + '&vpccid=' + model.vpccid; };
    return this._Request.Request(url, RequestMethod.Get);
  }

  public createDocumentary(model) {
    return this._Request.RequestWithToken('/api/v1/documentary/create', RequestMethod.Post, model);
  }

  public uploadFile(model) {
    return this._Request.RequestWithToken('/api/v1/Documentary_File/Add', RequestMethod.Post, model);
  }

  public confirmDocument(model) {
    return this._Request.RequestWithToken('/api/v1/documentary/confirm', RequestMethod.Post, model);
  }

  public update_vpcc_staff(model) {
    return this._Request.RequestWithToken('/api/v1/documentary/update_vpcc_staff', RequestMethod.Post, model);
  }

  public staffdetail(idstaff) {
    return this._Request.Request('/api/v1/staff/detail/' + idstaff, RequestMethod.Get);
  }

  public updateProfile(model) {
    return this._Request.RequestWithToken('/api/v1/Account/Update', RequestMethod.Post, model);
  }

  public getInfoUserByToken(token) {
    return this._Request.RequestWithToken('api/v1/Account/GetByToken/' + token, RequestMethod.Get);
  }

  // api load quan huyen
  public getLocation(id: string, idType: string) {
    return this._Request.Request('/api/v1/Region/GetRegionByParentIdAndTypeid?parentid=' + id + '&typeid=' + idType, RequestMethod.Get);
  }

  // thanh toan
  public payment(model) {
    return this._Request.RequestWithToken('/api/v1/Transaction/NapTien', RequestMethod.Post, model);
  }
  public paymentPayoo(model){
    return this._Request.RequestWithToken('api/v1/Payoo/CheckOut', RequestMethod.Post, model);
  }

  public Login(modaluser) {
    return this._Request.Request('/api/v1/Account/login', RequestMethod.Post, modaluser);
  }

  public confirmTime(model) {
    return this._Request.RequestWithToken('/api/v1/order/confirm/' + model.idHoSo, RequestMethod.Post, model);
  }

  public historyNotary(model) {
    return this._Request.RequestWithToken('/api/v1/DocumentaryLog/GetByUserID?ui=' + model, RequestMethod.Get);
  }
  // Get giá dịchvụ
  public getPrice(model) {
    var url = '/api/v1/fee_dervice/getbyvpccservice?';
    if (model.idVPCC) { url = url + '&vpccid=' + model.idVPCC; }
    if (model.idDichVu) { url = url + '&serviceid=' + model.idDichVu; }
    if (model.have_cost!=null && model.have_cost!=undefined) { url = url + '&cost=' + model.have_cost; }
    if (model.have_page) { url = url + '&sotrang=' + model.have_page; }
    if (model.have_copy) { url = url + '&soban=' + model.have_copy; }
    return this._Request.RequestWithToken(url, RequestMethod.Get);
  }
  // load vpcc theo dich vu
  public loadOfficeByServiceId(model) {
    let strquery = 'serviceid=' + model.serviceid;
    if (model.province) {
      strquery += '&province=' + model.province;
      if (model.district) {
        strquery += '&district=' + model.district;
      }
    }
    if (model.latlng) { strquery = strquery + '&latlng=' + model.latlng; }
    return this._Request.Request('/api/v1/tbl_VPCC_Service/ListByServiceID?' + strquery, RequestMethod.Get);
  }


  //delete file image personal
  public deletefile(model) {
    return this._Request.RequestWithToken('/api/v1/customer/delfile/' + model.id, RequestMethod.Get);
  }
  // load detail document
  public loadDetailDocument(model) {
    return this._Request.RequestWithToken('/api/v1/documentary/detail/' + model, RequestMethod.Get);
  }
  // load danh sách hồ sơ
  public loadDocument(model) {
    return this._Request.RequestWithToken('/api/v1/document/getbyuserid?ui=' + model.ui + '&status=' + model.status, RequestMethod.Get);
  }
  // public rating staff
  public rateCCV(model) {
    return this._Request.RequestWithToken('/api/v1/staff/rate', RequestMethod.Post, model);
  }
  // rating vpcc
  public ratVPCC(model) {
    return this._Request.RequestWithToken('/api/v1/vpcc/rate', RequestMethod.Post, model);
  }
  // remove image upload
  public removeImage(idImage) {
    return this._Request.RequestWithToken('/api/v1/file/delete/' + idImage, RequestMethod.Get);
  }
  // lay dieu khoan su dung
  public getDKSD() {
    return this._Request.Request('/api/v1/tbl_StaticContent/GetByKey/DIEU_KHOAN_SU_DUNG_DICH_VU', RequestMethod.Get);
  }
  //
  public getCSBM() {
    return this._Request.Request('/api/v1/tbl_StaticContent/GetByKey/CHINH_SACH_BAO_MAT', RequestMethod.Get);
  }
  public getListRoom(start, limit, search, userid){
    return this._Request.Request('/api/v1/Room/GetListRoom?start=' + start + '&limit=' + limit + '&search=' + search + '&userid=' + userid, RequestMethod.Get )
  }
  public getStaffByUserId(userid){
    return this._Request.Request('/api/v1/tb_Staff/GetViewDetailByUserID/' + userid, RequestMethod.Get)
  }
  //Load thông số hồ sơ hoàn thành
  public getPaidSummary(start, length, search, userid, statusid){
    return this._Request.Request('/api/v1/tbl_Booking/GetTotalPaid?start=' + start + '&length=' + length + '&search=' + search + '&userid=' + userid + '&statusid=' + statusid, RequestMethod.Get)
  }
}
