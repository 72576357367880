import { Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
export abstract class AppComponentBase {
  public translate: TranslateService;
  constructor(injector: Injector,public spinnerService: Ng4LoadingSpinnerService) {
    this.translate = injector.get(TranslateService);
    const language = localStorage.getItem('language');
    this.translate.use(language && language.match(/en|vi/) ? language : 'vi');
  }
  public display:boolean = false;
  show1s() {
    this.spinnerService.show();
    // setTimeout(()=>this.spinnerService.hide(),1000)
  }
  showTime(time){
    this.spinnerService.show();
    setTimeout(()=>this.spinnerService.hide(),time)
  }
  show() {
    this.spinnerService.show();
  }
  hide(){
    this.spinnerService.hide();
  }

  l(key: string, ...args: any[]): string {
    return this.translate.instant(key);
  }

}