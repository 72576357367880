
import { Component, TemplateRef, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  content: any;
  title: any;
  showphone: boolean = false;
  constructor() {
  }

  ngOnInit() {
  }
  
  showMyPhone(){
    this.showphone = !this.showphone;
  }
}
