import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GeoServiceService } from './../app/core/services/geo-service.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './layout/login/login.component';
import { RegisterComponent } from './layout/register/register.component';
import { ForgotPasswordComponent } from './layout/forgot-password/forgot-password.component';
import { MainComponent } from './layout/main/main.component';
import { HeaderComponent } from './layout/main/header/header.component';
import { FooterComponent } from './layout/main/footer/footer.component';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
//ngx
import { AuthGuard } from './guards/auth-guard.service';
import { AuthenService } from './core/services/auth-service.service';
import { DataService } from './core/services/data.service';
import { CustomRequest } from './libs/request';
import { NotificationService } from './libs/notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginService } from '../app//layout/login/login.component.service';
import { RegisterService } from '../app/layout/register/register.component.service';
import { VerifyAccountComponent } from './layout/verify-account/verify-account.component';
import { verifyAccount } from '../app/layout/verify-account/verify-account.component.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxQRCodeModule } from 'ngx-qrcode2';
//slider
import { NgImageSliderModule } from 'ng-image-slider';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
//Primeng
import { NgxCurrencyModule } from "ngx-currency";
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmServiceComponent } from './libs/confirm-service/confirm-service.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
//fontawesome
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//
import { AgmCoreModule } from '@agm/core';
//chat
import { WebsocketService } from "./core/services/websocket.service";
import { ChatService } from "./core/services/chat.service";
//ngx gallery

//login
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular5-social-login";
import {
  MatIconModule,
  MatButtonModule,
  MatCardModule
} from '@angular/material';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { HoanThanhComponent } from './layout/main/step-cong-chung/hoan-thanh/hoanthanh.component';
import { ModalModule } from 'ngx-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Configs
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("659005778181964")
        
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("254042815441-ss5qn178t137unbmq5nt16o3pf935om2.apps.googleusercontent.com")
       
      },
    ]
  );
  return config;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyAccountComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmServiceComponent,
    HoanThanhComponent
  ],
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    ProgressSpinnerModule,
    NgxCurrencyModule,
    BrowserModule,
    NgImageSliderModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    DropdownModule,
    BrowserModule,
    NgxQRCodeModule,
    SocialLoginModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    AngularFontAwesomeModule,
    FontAwesomeModule,
    OverlayPanelModule,
    
    ConfirmDialogModule,
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB-SN4CEnvfKKojMpm48nz4mBCUw07rBIg',
      libraries: ['geometry', 'places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,

    },
    ConfirmationService,
    AuthGuard,
    AuthenService,
    CustomRequest,
    NotificationService,
    LoginService,
    RegisterService,
    DataService,
    verifyAccount,
    CookieService,
    GeoServiceService,
    WebsocketService,
    ChatService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
