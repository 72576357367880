import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs/Rx";
import * as io from 'socket.io-client'
import { NotificationService } from '../../libs/notification';

export interface Message {
  author: string;
  message: string;
}

@Injectable()
export class ChatService {
  socket: any;
  readonly CHAT_URL = "https://chat.congchungtructuyen.vn:3000/";
  public messages: Subject<Message>;
  constructor(private noti: NotificationService) {
    this.socket = io(this.CHAT_URL);
  }
  connect() {
    this.socket.on('connect', (data) => { console.log('connect success', data) });
  }
  createRoom(model) {
    this.socket.emit('new-room', model.roomName, model.fullname, model.createduserid, model.avatar, model.idUserRoom, model.tokenUser);
  }
  joinRoom(roomName, fullname, createduserid, avatar, idUserRoom, tokenUser){
    this.socket.emit('new-user', roomName, fullname, avatar, idUserRoom, tokenUser);
  }
  notiCreateRoom() {
    this.socket.on('room-created', (data) => {
      //this.noti.SuccessNotification("create room");
      //console.log('create room', data)
    });
  }
  notiJoinRoom() {
    this.socket.on('user-connected', (data) => {
      //console.log("vào phòng thành công", data);
    })
  }
  sendMgs(mess) {
    //console.log(localStorage.userId);
    //console.log('send-chat-message { ' + mess.roomid + ', ' + mess.messenger + ', ' + mess.typeid + ', ' + localStorage.userId + ', ' + mess.receiverid + ', ' + mess.avatar + ', ' + mess.sendername +  ' }');
    this.socket.emit('send-chat-message', mess.roomid, mess.messenger, mess.typeid, localStorage.userId, mess.receiverid, mess.avatar, mess.sendername);
  }
}