import { Component, OnInit } from '@angular/core';
import { DataStorage } from '../../../shared/data-storage';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../core/services/data.service';
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';
import { ChatService } from '../../../core/services/chat.service';
import { LoginService } from '../../../../app/layout/login/login.component.service';


import { CustomRequest } from '../../../../app/libs/request';
import { RequestMethod } from '@angular/http';
import { NotificationService } from '../../../../app/libs/notification';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../app/model/user';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: []
})
export class HeaderComponent implements OnInit {
  isLogin: boolean = false;
  username: string;
  model: any = {};
  token: any;
  notifys: any[] = [];
  isNoRead: number=0;
  chatnotify: any[] = [];
  chatnumber: number = 0;
  chatlist: any[] = [];
  socket: any = {};
  user: User = {
    username: "",
    password: "",
    token:""
  }
  constructor(private dataService: DataService, 
    private router: Router, 
    private chatService: ChatService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private _Request: CustomRequest,
    private noti: NotificationService,
    private translate: TranslateService,
    private cookie :CookieService) {
      this.socket = this.chatService.socket;
      this.listenerMessage();
  }

  initLogin() {
    if (!localStorage.tokenUser) {
      return;
    }

   
    this.loadNoti();
    this.dataService.getListRoom(0,20,'',localStorage.userId).subscribe((res) => {
      this.chatlist = res.data;
      for(let i = 0; i < res.data.length; i++){
        if (res.data[i].mes.id > res.data[i].read){
          this.chatnumber = this.chatnumber + 1;
        }
      }
      localStorage.setItem("chatnotify", this.chatnumber.toString());
    });

    this.dataService.getInfoUserByToken(localStorage.tokenUser).subscribe((res) => {
      this.model = res.data;
      if (!res.data) {
        this.isLogin = false;
        localStorage.clear();
        this.cookie.deleteAll();
        return;
      }
      DataStorage.user.userId = this.model.id;
      this.username = this.model.fullname;
      this.isLogin = true;
      this.username = this.GetLastName(this.username);
    }, error => {
      this.isLogin = false;
      localStorage.clear();
      this.cookie.deleteAll();
    });
  }

  GetLastName(stringName): string {
    var name = stringName;
    var index = name.lastIndexOf(' ');
    name = name.slice(
      name.lastIndexOf(' ') + 1
    );
    return name;
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('token');
    console.log(this.token);
    if(this.token && !this.isLogin)
    {
     this.goLogin();
    }
    this.initLogin();
    this.loadScript();
  }

  goLogin()
  {
    this.user.token=this.token;
    this.Login(this.user).subscribe((res) => {
      if (JSON.stringify(res.status) === 'true') {
        this.noti.SuccessNotification(this.translate.instant("Login.Success"));
        localStorage.userId = res.id;
        localStorage.tokenUser = res.token;
        this.isLogin=true;
        this.GotoMain();
      }
      else {
        this.noti.ErrorNotification(this.translate.instant("Login.EnterAgain"));
      }
    }, error => {
      this.noti.ErrorNotification(error);
    });
  }
  Login(modaluser) {
    return this._Request.Request('api/v1/Account/LoginFina', RequestMethod.Post, modaluser);
  }
//router
GotoMain() {
  this.router.navigate(['home']);
}
  gotoLogin() {
    this.router.navigate(['login']);
  }
  gotoRegister() {
    this.router.navigate(['register']);
  }
  gotoHome() {
    this.router.navigate(['home']);
  }
  gotoUserInfo() {
    this.router.navigate(['userinfo']);
  }
  Move(notify){
    let model:any={};
    model.id = notify.id;
    this.dataService.clickNotify(model).subscribe((res) => {
    this.loadNoti();
    if(notify.statusid == 2 || notify.statusid == 1){
      localStorage.documentstab =2
    } else if(notify.statusid == 3){
      localStorage.documentstab =3
    } else{
      localStorage.documentstab =7
    } if(notify.statusid == 9) {
       return this.router.navigate(['historyAction']);
    }
    if (this.router.url.includes('documents')){
      location.reload()
    }
    else{
      this.router.navigate(['documents']);
    }
    });
    
  }
  SeeMore(){
    console.log("see more")
  }
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    //custom
    let script = document.createElement('script');

    script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/header.js';
    
    body.appendChild(script);
  }
  listenerMessage(){
    this.socket.on('chat-message', (data) => {
      let nomessage = false;
      //Nhan dc tin nhan cua chinh minh
      if (data.userid === localStorage.userId){
        for (let i=0; i< this.chatlist.length; i++){
          let customerid = this.chatlist[i].room.userid_customer.toString();
          if(customerid === data.userid){
            // => Neu co tin nhan chua doc thi giam so tin thong bao => Tuc la da doc
            if (this.chatlist[i].mes.id > this.chatlist[i].read){
              this.chatnumber = this.chatnumber - 1;
              if(this.chatnumber < 0) this.chatnumber = 0;
              this.chatlist[i].read = this.chatlist[i].mes.id;
              return;
            }
            else{
              return;
            }
          }
        }
      }
      //Nhan dc tin nhan cua cong chung vien
      if (data.receiverid === localStorage.userId){
        for (let i=0; i< this.chatlist.length; i++){
          let staffid = this.chatlist[i].room.userid_staff.toString();
          if (staffid === data.userid){
            nomessage = true;
            if (this.chatlist[i].mes.id === this.chatlist[i].read){
              this.chatnumber = this.chatnumber + 1;
              this.chatlist[i].mes.id = Number(this.chatlist[i].mes.id) + 1
              return;
            }
            else{
              return;
            }
          }
        }
        if(nomessage === false){
          this.chatnumber = this.chatnumber + 1;
        }
      }
      localStorage.setItem("chatnotify", this.chatnumber.toString());
    })
  }

  loadNoti(){
    if(localStorage.userId) {
      this.dataService.getNotify(localStorage.userId).subscribe((res) => {
        this.notifys = res.data;
        this.isNoRead = res.countNoRead;
      });
    }
    setTimeout(() => {
    this.loadNoti();
    }, 30000);
  }

  readNoti(){
    let model:any={};
    this.isNoRead = 0;
    model.userid = localStorage.userId;
    this.dataService.readNotify(model).subscribe((res) => {
      // this.notifys = res.data;
      // this.isNoRead = res.isNoRead;
    });
  }

  clickNoti(id){
   
  }

}
