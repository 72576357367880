import { Http, Headers, Request, RequestOptions, RequestMethod, Response, ResponseContentType } from '@angular/http';
import { SystemConstants } from '../core/common/system.constant';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { NotificationService } from './notification';
import { Router } from '@angular/router';

@Injectable()
export class CustomRequest {
  myAppUrl: string = "";

  constructor(
    private http: Http,
    private cookieService: CookieService,
    private router: Router,
    private notify: NotificationService) {
    this.myAppUrl = SystemConstants.BASE_API;
  }

  makeRequest(url: string, method: RequestMethod, body?: Object) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", this.cookieService.get('token'));
    headers.append("Access-Control-Max-Age", "3600");
    const requestOptions = new RequestOptions({
      url: `${this.myAppUrl}/${url}`,
      method: method,
      headers: headers
    });
    if (body) {
      requestOptions.body = body;
    }
    const request = new Request(requestOptions);
    return this.http.request(request)
      .pipe(
        map((res: any) => res.json()),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
    //.pipe(map((response: any) => response.json()))
    //.map((res: Response) => res.json())
    //.catch((res: Response) => this.onError(res.json().message));
  }

  Request(url: string, method: RequestMethod, body?: Object) {
    const headers = new Headers();
    // const user = JSON.parse(localStorage.getItem(SystemConstants.CURRENT_USER));
    headers.append("Content-Type", "application/json");
    headers.append("token", localStorage.tokenUser);
    headers.append("Access-Control-Max-Age", "3600");
    const requestOptions = new RequestOptions({
      url: `${this.myAppUrl}/${url}`,
      method: method,
      headers: headers
    });
    if (body) {
      requestOptions.body = body;
    }
    const request = new Request(requestOptions);
    return this.http.request(request)
      .pipe(
        map((res: any) => res.json()),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
    //.pipe(map((response: any) => response.json()))
    //.map((res: Response) => res.json())
    //.catch((res: Response) => this.onError(res.json().message));
  }

  RequestWithToken(url: string, method: RequestMethod, body?: Object): Observable<any> {
    const headers = new Headers();
    // const user = JSON.parse(localStorage.getItem(SystemConstants.CURRENT_USER));
    headers.append("Content-Type", "application/json");
    headers.append("token", localStorage.tokenUser);
    headers.append("Access-Control-Max-Age", "3600");

    const requestOptions = new RequestOptions({
      url: `${this.myAppUrl}/${url}`,
      method: method,
      headers: headers
    });
    if (body) {
      requestOptions.body = body;
    }
    const request = new Request(requestOptions);
    return new Observable(observer => {
      this.http.request(request)
        .subscribe((response) => {
          let res = response.json();
          if (!res.status && (res.error == 'token_not_exist' || res.error == 'expired')) {
            this.notify.ErrorNotification('Phiên bản đăng nhập đã hết hạn! Quý khách vui lòng đăng nhập lại');
            localStorage.tokenUser = "";
            this.cookieService.deleteAll();
            this.router.navigate(['login']);
            observer.error();
            return;
          }
          observer.next(res);
        }, error => {
          observer.error(error);
        });
    });
  }

  onError(res: Response) {
    const statusCode = res.status;
    const body = res.json();
    const error = {
      statusCode: statusCode,
      error: body.error
    };
    return Observable.throw(error);
  }
}
